import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import NavBar from '../Navbar';
import ScrollToTop from '../../components/ScrollToTop';
import FormInput from '../../layouts/FormInput';
import SelectUI from '../../layouts/Select';

import { tips } from '../../constants/tips';
import { UserContext } from '../../components/UserContext';

//styles
import styles from '../../styles/donations.module.css';
import style from '../../styles/movementCard.module.css';
import Accordion from '../../layouts/Accordion';
import StripeCheckoutForm from './CustomStripeForm';
import { FaAngleLeft } from 'react-icons/fa';

const PaymentOptions = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('credit_card');

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };
 
  function calculateStripeFee(donation, tipPercentage, coverStripeFee = false) {
    const tip = donation * (tipPercentage / 100);
    const totalAmount = donation + tip;
console.log(coverStripeFee,' cover the feee')
    // Calculate the Stripe fee: 2.9% + $0.30
    const stripeFee = totalAmount * 0.029 + 0.30;
    const finalAmount = coverStripeFee ? totalAmount + stripeFee : totalAmount;

    return {
        donation,
        tip,
        totalAmount,
        stripeFee,
        finalAmount
    };
}

  const paymentOptions = [
/*     { id: 'google_pay', label: 'Google Pay' },
    { id: 'apple_pay', label: 'Apple Pay' },
    { id: 'paypal', label: 'PayPal' }, */
    { id: 'credit_card', label: 'Credit/Debit Card' },
  ];

  let submissionObject = {};
  const storedSubmission = localStorage.getItem('donationSubmission');
  if (storedSubmission) {
    submissionObject = JSON.parse(storedSubmission);
    console.log(submissionObject,storedSubmission); // This will log the stored object
  } else {
    console.log('No submission data found in local storage');
  }

  const charityName = submissionObject.charityName;
  const location = useLocation();
  const navigate = useNavigate();

  submissionObject['fee'] = submissionObject.fee =='true';

const pricingObject = calculateStripeFee(submissionObject['yourDonation']/100,submissionObject.tipAmount,submissionObject.fee);
submissionObject['original_amount'] = pricingObject.donation;
submissionObject['money'] = pricingObject.finalAmount;
submissionObject['tip'] = pricingObject.tip;
submissionObject['donation'] = pricingObject.donation;
submissionObject['fee_number'] = pricingObject.stripeFee;
submissionObject['amount'] = pricingObject.finalAmount;
console.log("submission object",submissionObject);


  const goBack = () => {
    navigate(-1);
  };
  const context = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log("data submitted",data);
    // navigate('/make/payment/id');
  };
  console.log(errors);

  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.back_btn_container}>
          <FaAngleLeft className={styles.back_btn} onClick={goBack} size={35} />

          <p className={styles.title}>Payment Method</p>
        </div>
        <div className={styles.user}>
          <img className={styles.photo} src={context.photoUrl} alt='' />
          <p className={styles.userName}>
            <span className={styles.charityName}>
              {submissionObject.charityName}
            </span>
            {context.displayName}
          </p>
        </div>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <br />
        <ScrollToTop />
        <div className={styles.formsContainer}>
          <Accordion
            paymentOptions={paymentOptions}
            onPaymentMethodChange={handlePaymentMethodChange}
          />
          {selectedPaymentMethod === 'credit_card' && (
            <div className={styles.creditCardForm}>
              {/* Render your form inputs here */}
          <StripeCheckoutForm
                amount={parseFloat(submissionObject.money.toFixed(2))*100}
                productName={submissionObject.movementId}
                charity={submissionObject.charityName}
                fee={submissionObject.fee}
                fee_number={submissionObject.fee_number}
                tip={submissionObject.tip}
                userId={context.userID || 'anonymous user'}
                anonymousUser={!context.userID}
                donation={parseFloat(submissionObject.donation.toFixed(2))}x
              /> 
               <div className={style.details}>
                      <div className={style.card_details}>
                        <span className={style.card_title_one}>Your donation</span>

                        <span className={style.card_title_one}>${submissionObject.yourDonation/100}</span>
                      </div>
                      <div className={style.card_detail_2}>
                        <span className={style.card_title_two}>
        Move For Change tip  ( {submissionObject.tipAmount}% )
                        </span>
                        <span className={style.card_title_two}>
                        ${calculateStripeFee(submissionObject['yourDonation']/100,submissionObject.tipAmount,submissionObject.fee).tip}
                        </span>
                      </div>
                     { submissionObject.fee && <div className={style.card_detail_2}>
                        <span className={style.card_title_two}>
        Transaction fee cover ( 2.9% + $0.30)
                        </span>
                        <span className={style.card_title_two}>
                         ${calculateStripeFee(submissionObject['yourDonation']/100,submissionObject.tipAmount,submissionObject.fee).stripeFee.toFixed(2)}
                        </span>
                      </div>}
                      <div className={style.card_detail_2}>
                        <span className={style.card_title_two}>
        Total donation
                        </span>
                        <span className={style.card_title_two}>
                        ${calculateStripeFee(submissionObject['yourDonation']/100,submissionObject.tipAmount,submissionObject.fee).finalAmount.toFixed(2)}
                        </span>
                      </div>
                    </div>
            </div>
          )}
              
        </div>
        <br />
      </div>
    </>
  );
};

export default PaymentOptions;